import { isStorageAvailable } from '@jsmdg/browser-storage';
import { StorageType } from '@jsmdg/browser-storage/dist/services/browser-storage-item';

const getUsercentricsSettings = function (): string | undefined {
    if (isStorageAvailable(StorageType.LOCAL_STORAGE)) {
        return window.localStorage?.getItem('uc_settings');
    }

    return undefined;
};

export { getUsercentricsSettings };
