import type * as Sentry from '@sentry/browser';

import { ERROR_IGNORE_LIST } from './const';

const escapeRegExp = (string: string) => {
    return string.replaceAll(/[$()*+.?[\\\]^{|}]/gu, '\\$&'); // Escape special regex characters
};

export const canIgnoreEventFromErrorsList = (event: Sentry.Event): boolean => {
    const exceptionValue = event.exception?.values?.[0]?.value;
    if (exceptionValue) {
        return ERROR_IGNORE_LIST.some((filterItem: string | RegExp) => {
            const regex =
                filterItem instanceof RegExp ? filterItem : new RegExp(escapeRegExp(filterItem));

            return regex.test(exceptionValue);
        });
    }

    return false;
};
