/* eslint-disable no-underscore-dangle */
import { getUsercentricsSettings } from './getUserCentricsSettings';
import { type TcfDataType } from './types/tcfData';
import { type UsercentricSettingsType } from './types/usercentricsSettings';

window.addEventListener('load', () => {
    const usercentricsServiceTemplateId = Object.values(
        window.Evelin?.data?.julien?.usercentricsTrackingTemplateIds,
    );

    // we need this in order to send the usercentrics cookie consent information via the request to the Lambda@Edge and Julien
    const usercentricsEventHandler = function (tcfData: TcfDataType, success: boolean): void {
        const usercentricsSettings: string | undefined = getUsercentricsSettings();

        const areUsercentricsSettingsReady =
            success && usercentricsSettings && tcfData.eventStatus === 'useractioncomplete';

        if (!areUsercentricsSettingsReady) {
            return;
        }

        const usercentricsSettingsObject = JSON.parse(
            usercentricsSettings,
        ) as UsercentricSettingsType;

        const filteredUsercentricsServices = usercentricsSettingsObject.services
            ?.map(service =>
                usercentricsServiceTemplateId.includes(service.id)
                    ? { id: service.id, status: service.status }
                    : null,
            )
            .filter(Boolean);

        if (!filteredUsercentricsServices?.length) {
            return;
        }

        const cookieDate = new Date();
        cookieDate.setFullYear(cookieDate.getFullYear() + 1);
        // eslint-disable-next-line @microsoft/sdl/no-cookies
        document.cookie = `uc-evelin-tracking-settings=${JSON.stringify(
            filteredUsercentricsServices,
        )}; expires=${cookieDate.toUTCString()}; path=/;`;

        // eslint-disable-next-line no-void
        void fetch(window.location.href, {
            method: 'HEAD',
            // we need to send the original referer as a query param to not loose it
            // its called referer instead of referrer here, since the cookie-generation-js library is reading it and we only deal with "referer" there
            referrer:
                document.referrer && document.referrer !== window.location.origin
                    ? `${window.location.origin}?original_referer=${document.referrer}`
                    : '',
        });
    };

    window.__tcfapi?.('addEventListener', 2, (tcfData: TcfDataType, success: boolean) => {
        setTimeout(() => usercentricsEventHandler(tcfData, success), 0);
    });
});
