import { v4 as uid } from '@lukeed/uuid';
import * as Sentry from '@sentry/browser';
import { type Integration } from '@sentry/types';

import { getSettingStorage } from '@jsmdg/browser-storage';
import { canIgnoreEventFromErrorsList } from './canIgnoreErrorFromList';
import { ALGOLIA_REQUEST_REGEX, ERROR_IGNORE_LIST, REDIRECT_TO_SENTRY_PROJECT_KEY } from './const';
import { extractDsnAndReleaseDataFromFragment } from './extractDsnAndReleaseDataFromFragment';
import { eventHasRouteToRedirect } from './types/eventhasRoutetoRedirect.guard';
import { framesHaveMandatorySentryRedirectProperties } from './types/frameHasMandatorySentryRedirectProperties.guard';
import { type SentryWithModuleMetaData } from './types/SentryWithModuleMetaData';
import { stackTraceFrameHasModuleMetaData } from './types/stackTraceFrameHasModuleMetaData.guard';

const setSentryUser = function (scope: typeof Sentry): void {
    let id: string;

    try {
        if (!getSettingStorage().get().sentryUserId) {
            getSettingStorage().setItem('sentryUserId', uid());
        }

        id = getSettingStorage().get().sentryUserId;
    } catch {
        id = `${Date.now()}`;
    }

    scope.setUser({ id });
};

const registerSentryClient = (fragmentId: string, client: typeof Sentry): void => {
    window.Evelin.sentryClients[fragmentId] = client;
    setSentryUser(client);
};

if (!window.Sentry) {
    window.Sentry = Sentry;
}

if (!window.Evelin.sentryClients) {
    window.Evelin.sentryClients = {};
}

if (!window.Evelin.registerSentryClient) {
    window.Evelin.registerSentryClient = registerSentryClient;
}

Sentry.init({
    tracePropagationTargets: [window?.location?.origin],
    dsn: window?.Evelin?.data?.julien?.sentryDSN,
    transport: Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, ({ getEvent }) => {
        // This is how sentry recommends event-redirects in a MFE-setup, for more see:
        // https://docs.sentry.io/platforms/javascript/guides/electron/configuration/micro-frontend-support/#combining-modulemetadata-and-makemultiplexedtransport
        const event: Sentry.Event | undefined = getEvent();
        if (event && eventHasRouteToRedirect(event)) {
            return event.extra[REDIRECT_TO_SENTRY_PROJECT_KEY];
        }

        return [];
    }),
    integrations: [
        Sentry.moduleMetadataIntegration(),
        window?.Evelin?.data?.julien?.sentryThirdPartyErrorFilteringEnabled &&
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: ['Evelin'],
                behaviour: 'drop-error-if-contains-third-party-frames',
            }),
        window?.Evelin?.data?.julien?.sentryTracingEnabled && Sentry.browserTracingIntegration(),
        window?.Evelin?.data?.julien?.sentrySessionReplayEnabled &&
            Sentry.replayIntegration({
                networkDetailAllowUrls: [
                    '/fragments/cart/api/cart/remove-item',
                    ALGOLIA_REQUEST_REGEX,
                ],
            }),
    ].filter((item): item is Integration => Boolean(item)), // eslint-disable-line unicorn/prefer-native-coercion-functions
    ...(window?.Evelin?.data?.julien?.sentryTracingEnabled && {
        tracesSampleRate: window?.Evelin?.data?.julien?.sentrySampleRate,
    }),

    ...(window?.Evelin?.data?.julien?.sentrySessionReplayEnabled && {
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: window?.Evelin?.data?.julien?.replaysOnErrorSampleRate,
    }),

    autoSessionTracking: true,

    release: `${window?.Evelin?.data?.julien?.release}`,
    environment: window?.Evelin?.data?.julien?.environment,
    debug: false,
    attachStacktrace: true,
    denyUrls: [
        // Facebook urls
        /graph\.facebook\.com/iu,
        /connect\.facebook\.net\/en_us\/all\.js/iu,

        // Chrome urls
        /chrome-extension:\//iu,
        /extensions\//iu,
        /^chrome:\/\//iu,

        // Safari urls
        /safari-extension:\//iu,

        // Tiktok urls
        /analytics\.tiktok\.com/iu,

        /initinstantpage/iu,

        // GTM related urls
        /gtm/iu,
        /gtag/iu,

        // Miscellaneous urls
        /\/pagead\//iu, // https://sentry.io/organizations/jsmdh/issues/1732629736/?_allp=1&query=is%3Aunresolved&sort=freq&statsPeriod=24h Google syntax error
        /src\/transports\/base/iu,
        /usercentrics/iu,
        /browser-ui/iu,
    ],
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: ERROR_IGNORE_LIST,
    beforeSend(event: Sentry.ErrorEvent): Sentry.ErrorEvent | null {
        // We need to manually filter out errors here because the `ignoreErrors` field is currently not functioning as expected.
        // Refer to this issue for more details: https://github.com/getsentry/sentry/issues/48997
        // It’s important to check with each new Sentry update to see if this behavior has been fixed.
        if (canIgnoreEventFromErrorsList(event)) {
            return null;
        }

        const stacktraceFrames: SentryWithModuleMetaData[] =
            event.exception?.values?.[0].stacktrace?.frames ?? [];

        const lastFrameWithRedirectRouteArray = stacktraceFrames
            .filter(stackTraceFrameHasModuleMetaData)
            .map(extractDsnAndReleaseDataFromFragment)
            .slice(-1);

        return framesHaveMandatorySentryRedirectProperties(lastFrameWithRedirectRouteArray)
            ? {
                  ...event,
                  extra: {
                      ...event.extra,
                      [REDIRECT_TO_SENTRY_PROJECT_KEY]: lastFrameWithRedirectRouteArray,
                  },
              }
            : event;
    },
});

setSentryUser(Sentry);

window.logToSentry = Sentry.captureException;
