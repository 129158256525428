import * as React from 'react';
import * as jsx from 'react/jsx-runtime';
import * as ReactDom from 'react-dom';
import * as ReactIntl from 'react-intl';

window.Evelin.fragments['react-base-fragment'] = {
    React,
    ReactDom,
    ReactIntl,
    jsx,
};

window.dispatchEvent(new Event('evelin:ready'));
