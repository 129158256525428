/* eslint import/no-unassigned-import: "off" */
import './requestIdleCallback';
import './sentry';
import './usercentrics';
import './react';

window.addEventListener('load', () => {
    window.requestIdleCallback((): void => {
        setTimeout(() => {
            import(/* webpackChunkName: "v1" */ './initVersionCheck');
        }, 60_000); // 1min

        if (window.Evelin?.data?.julien && !window.Evelin.data.julien.prefetch) return;
        import(/* webpackChunkName: "web-vitals" */ './webVitals');
        import(/* webpackChunkName: "instantpage523" */ '@jsmdg/instantpage/instantpage');
    });
});

/** imgix fallback */
document.body.addEventListener(
    'error',
    error => {
        const img = error.target as HTMLImageElement;
        if (img.tagName !== 'IMG' || !navigator.onLine) return;
        const { src } = img;
        if (!src.includes('/images/') || !src.includes('jsmd-group.com')) return;

        // exmaple input: https://www.jochen-schweizer.de/images/https%3A%2F%2Fstaging.static.jsmd-group.com%2Fassets%2Fnew_default_upload_bucket%2FThumbnail_Wellness_1.jpg?w=276&h=190&fit=clip&auto=format%2Ccompress&cs=srgb&dpr=1&s=162b50ff9d700387ef9a0b8762438aed
        // by stripping everything from the start and then decoding the path, we load the original image.
        const newSource = decodeURIComponent(src.replace(/^.*\/images\//u, ''));
        if (src === newSource) return;

        img.src = newSource;
        img.srcset = ''; // remove any srcset so the original src loads.
    },
    { capture: true },
);

window.yieldToMainThread = async (): Promise<void> => {
    if (
        'scheduler' in window &&
        typeof window.scheduler === 'object' &&
        window.scheduler !== null
    ) {
        if ('yield' in window.scheduler && typeof window.scheduler.yield === 'function') {
            await window.scheduler.yield();
            return;
        }

        if ('postTask' in window.scheduler && typeof window.scheduler.postTask === 'function') {
            // We choose user-visible since user can interact with the page during the yielding interlude.
            // eslint-disable-next-line no-empty-function
            await window.scheduler.postTask(() => {}, { priority: 'user-visible' });
            return;
        }
    }

    await new Promise(resolve => {
        setTimeout(resolve, 0);
    });
};

window.withYieldCallback = <T extends CallableFunction>(function_: T): (() => void) => {
    return () => {
        // eslint-disable-next-line unicorn/consistent-function-scoping
        const yieldFunction = async (): Promise<void> => {
            await window.yieldToMainThread();
            function_();
        };

        // we don't need to `await` here, because the async function above will `await` the main thread yield
        // and then call the callback function.
        // eslint-disable-next-line no-void
        void yieldFunction();
    };
};
