import { type SentryWithModuleMetaData } from './types/SentryWithModuleMetaData';

const extractDsnAndReleaseDataFromFragment = function ({
    module_metadata: moduleMetaData,
}: SentryWithModuleMetaData): {
    dsn: string;
    release: string;
} {
    const { dsn, release } =
        window.Evelin.sentryClients[moduleMetaData.name]?.getClient()?.getOptions() ?? {};

    return { dsn, release };
};

export { extractDsnAndReleaseDataFromFragment };
