if (!window.requestIdleCallback) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error -- poor man's polyfill
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    window.requestIdleCallback = callback => {
        window.setTimeout(() => {
            // eslint-disable-next-line promise/prefer-await-to-callbacks,@typescript-eslint/no-unsafe-call
            callback({ didTimeout: true, timeRemaining: () => 1 });
        }, 0);
    };
}
